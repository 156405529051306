<template>
  <div>
    <h1>Book</h1>
    <h2>ID {{ bookId }}</h2>
  </div>
</template>

<script>
export default {
  name: 'BookPage',
  computed: {
    bookId: function () {
      return this.$route.params.book.split('-').pop()
    }
  }
}
</script>
